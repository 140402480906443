.language {
    display: inline;
  }
  .heading {
    font-size: 20px;
  }
  #source {
    width: 100% !important;
    background: #cccccc52;
    height: 50vh;
    font-size: 15px;
    resize: vertical;
  }
  #input {
    background: #cccccc52;
    width: 45.6%;
    height: 17vh;
    resize: vertical;
    font-size: 17px;
  }
  #output {
    background: #cccccc52;
    width: 50%;
    height: 100%;
    position: fixed;
    top: 7.6%;
    font-size: 18px;
    right: 0;
    resize: none;
  }
  .language {
    width: 150px;
    margin-left: 16px;
    background-color: #2D3A42;
    color: #FFFFFF
  }

  .ace_editor{background: #212B31!important}

  .ace_gutter-cell{background: #212B31!important}

  .ace_gutter-layer{background: #212B31!important}

  .soal > * {
    color: white !important
  }

  .ace_print-margin{
    visibility: hidden !important;
  }

  .instabody  * { 
    color: white !important;
    background-color: #36454F !important;
  }

  .scrollbar
{
	/* margin-left: 30px; */
	/* float: left;
	height: 300px;
	width: 65px;
	background: #F5F5F5;
	overflow-y: scroll;
	margin-bottom: 25px; */
  overflow-y: scroll;
}


/*
 *  STYLE 1
 */

 #style-1::-webkit-scrollbar-track
 {
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
   border-radius: 7px;
   background-color: #272525;
 }
 
 #style-1::-webkit-scrollbar
 {
   width: 7px;
   background-color: #291818;
   background-color: #555;

 }
 
 #style-1::-webkit-scrollbar-thumb
 {
   border-radius: 7px;
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
   background-color: #555;
   
 }